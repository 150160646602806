import React from "react";
import { IconCheckGreenLight } from "assets/media/svgs";
import { SAVINGS_DATA } from "data/pages";
import { uniqueId } from "lodash";

import * as styles from "./Features.module.scss";

const Features = () => {
  const { FEATURES } = SAVINGS_DATA;
  return (
    <section className={styles.Features}>
      <header className={styles.Features_header}>
        <h3 className={styles.Features_header_title}>
          Automated savings with full control
        </h3>

        <p className={styles.Features_header_desc}>
          Adjust your savings goals. Pause, manage and withdraw your savings any
          time anytime you want.
        </p>
      </header>

      <div className={styles.Features_contents}>
        {FEATURES.map(({ title, desc, img, contents, type }) => (
          <div
            key={uniqueId("features-")}
            className={styles.Features_contents_item}
          >
            <h3 className={styles.Features_contents_item_title}>{title}</h3>

            <div className={styles.Features_contents_item_media}>
              <img src={img} alt={title} />

              <div className={styles.Features_contents_item_media_info}>
                <div className={styles.Features_contents_item_media_info_top}>
                  <span
                    className={
                      styles.Features_contents_item_media_info_top_name
                    }
                  >
                    {type.name}
                  </span>

                  <span
                    className={
                      styles.Features_contents_item_media_info_top_type
                    }
                  >
                    {type.savings_type}
                  </span>
                </div>

                <h1 className={styles.Features_contents_item_media_info_rate}>
                  {type.rate}%
                </h1>

                <p
                  className={styles.Features_contents_item_media_info_interest}
                >
                  Interest per annum
                </p>
              </div>
            </div>

            <div className={styles.Features_contents_item_info}>
              <div className={styles.Features_contents_item_info_top}>
                <h3 className={styles.Features_contents_item_info_top_title}>
                  {title}
                </h3>

                <p className={styles.Features_contents_item_info_top_desc}>
                  {desc}
                </p>
              </div>

              <div className={styles.Features_contents_item_info_details}>
                <p className={styles.Features_contents_item_info_details_label}>
                  {contents.label}
                </p>

                <ul
                  className={
                    styles.Features_contents_item_info_details_features
                  }
                >
                  {contents.features.map((item) => (
                    <li key={uniqueId("features-item-")}>
                      <IconCheckGreenLight />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
