// extracted by mini-css-extract-plugin
export var Contributions = "Contributions-module--Contributions--zPm02";
export var Contributions_automate = "Contributions-module--Contributions_automate--OgAMx";
export var Contributions_automate_img = "Contributions-module--Contributions_automate_img--ooN4a";
export var Contributions_automate_title = "Contributions-module--Contributions_automate_title--ToSo2";
export var Contributions_terminal_details = "Contributions-module--Contributions_terminal_details--t-e1S";
export var Contributions_terminal_details_img = "Contributions-module--Contributions_terminal_details_img--6EOMt";
export var Contributions_terminal_details_text = "Contributions-module--Contributions_terminal_details_text--AiCCO";
export var Contributions_terminal_meta = "Contributions-module--Contributions_terminal_meta--BEzvx";
export var Contributions_terminal_title = "Contributions-module--Contributions_terminal_title--qKeQg";
export var container = "Contributions-module--container--RUQw3";
export var gatsbyImageWrapperConstrained = "Contributions-module--gatsby-image-wrapper-constrained--7Kp-2";
export var gridSpan1010 = "Contributions-module--grid-span-10-10--qLjNx";
export var gridSpan1011 = "Contributions-module--grid-span-10-11--WfurK";
export var gridSpan102 = "Contributions-module--grid-span-10-2--jzqnm";
export var gridSpan103 = "Contributions-module--grid-span-10-3--uywO-";
export var gridSpan104 = "Contributions-module--grid-span-10-4--rOVss";
export var gridSpan105 = "Contributions-module--grid-span-10-5--PVk-8";
export var gridSpan106 = "Contributions-module--grid-span-10-6--x67ur";
export var gridSpan107 = "Contributions-module--grid-span-10-7--wEMP7";
export var gridSpan108 = "Contributions-module--grid-span-10-8--94LXM";
export var gridSpan109 = "Contributions-module--grid-span-10-9--b8+fX";
export var gridSpan110 = "Contributions-module--grid-span-1-10--hG+ev";
export var gridSpan111 = "Contributions-module--grid-span-1-11--zXwGn";
export var gridSpan1110 = "Contributions-module--grid-span-11-10--iJaaj";
export var gridSpan1111 = "Contributions-module--grid-span-11-11--C1CSG";
export var gridSpan112 = "Contributions-module--grid-span-11-2--x5vdM";
export var gridSpan113 = "Contributions-module--grid-span-11-3--9ZBOa";
export var gridSpan114 = "Contributions-module--grid-span-11-4--PPVzP";
export var gridSpan115 = "Contributions-module--grid-span-11-5--aAl2i";
export var gridSpan116 = "Contributions-module--grid-span-11-6---wvpE";
export var gridSpan117 = "Contributions-module--grid-span-11-7--+EgcQ";
export var gridSpan118 = "Contributions-module--grid-span-11-8--Mbine";
export var gridSpan119 = "Contributions-module--grid-span-11-9--Zh1r8";
export var gridSpan12 = "Contributions-module--grid-span-1-2--D4a+Q";
export var gridSpan1210 = "Contributions-module--grid-span-12-10--YuBFq";
export var gridSpan1211 = "Contributions-module--grid-span-12-11--y-alc";
export var gridSpan122 = "Contributions-module--grid-span-12-2---hWPX";
export var gridSpan123 = "Contributions-module--grid-span-12-3--9ucjh";
export var gridSpan124 = "Contributions-module--grid-span-12-4--JWt+B";
export var gridSpan125 = "Contributions-module--grid-span-12-5--873GB";
export var gridSpan126 = "Contributions-module--grid-span-12-6--tBzso";
export var gridSpan127 = "Contributions-module--grid-span-12-7--IYuu3";
export var gridSpan128 = "Contributions-module--grid-span-12-8--Hpk-x";
export var gridSpan129 = "Contributions-module--grid-span-12-9--LZUoq";
export var gridSpan13 = "Contributions-module--grid-span-1-3--hssq+";
export var gridSpan14 = "Contributions-module--grid-span-1-4--D60c0";
export var gridSpan15 = "Contributions-module--grid-span-1-5--YcCzK";
export var gridSpan16 = "Contributions-module--grid-span-1-6--aye5q";
export var gridSpan17 = "Contributions-module--grid-span-1-7--2sSJn";
export var gridSpan18 = "Contributions-module--grid-span-1-8--k2KpY";
export var gridSpan19 = "Contributions-module--grid-span-1-9--BH2e8";
export var gridSpan210 = "Contributions-module--grid-span-2-10--a8oNL";
export var gridSpan211 = "Contributions-module--grid-span-2-11--lj3mJ";
export var gridSpan22 = "Contributions-module--grid-span-2-2--sDeRE";
export var gridSpan23 = "Contributions-module--grid-span-2-3--sQydQ";
export var gridSpan24 = "Contributions-module--grid-span-2-4--uCy+E";
export var gridSpan25 = "Contributions-module--grid-span-2-5--YXgxn";
export var gridSpan26 = "Contributions-module--grid-span-2-6--KDzzt";
export var gridSpan27 = "Contributions-module--grid-span-2-7--SHl5c";
export var gridSpan28 = "Contributions-module--grid-span-2-8--Jbswa";
export var gridSpan29 = "Contributions-module--grid-span-2-9--0CdbB";
export var gridSpan310 = "Contributions-module--grid-span-3-10--jrKqh";
export var gridSpan311 = "Contributions-module--grid-span-3-11--x19l8";
export var gridSpan32 = "Contributions-module--grid-span-3-2--rOJR2";
export var gridSpan33 = "Contributions-module--grid-span-3-3--7VJ5H";
export var gridSpan34 = "Contributions-module--grid-span-3-4--gtQVO";
export var gridSpan35 = "Contributions-module--grid-span-3-5--DLGfH";
export var gridSpan36 = "Contributions-module--grid-span-3-6--KWSWo";
export var gridSpan37 = "Contributions-module--grid-span-3-7--CpGN6";
export var gridSpan38 = "Contributions-module--grid-span-3-8--5zHu6";
export var gridSpan39 = "Contributions-module--grid-span-3-9--EGMYk";
export var gridSpan410 = "Contributions-module--grid-span-4-10--Ii55y";
export var gridSpan411 = "Contributions-module--grid-span-4-11--5C60O";
export var gridSpan42 = "Contributions-module--grid-span-4-2--Ad78l";
export var gridSpan43 = "Contributions-module--grid-span-4-3--efY1b";
export var gridSpan44 = "Contributions-module--grid-span-4-4--0bRfe";
export var gridSpan45 = "Contributions-module--grid-span-4-5--vekYc";
export var gridSpan46 = "Contributions-module--grid-span-4-6--TSd2B";
export var gridSpan47 = "Contributions-module--grid-span-4-7--REutV";
export var gridSpan48 = "Contributions-module--grid-span-4-8--IN4OV";
export var gridSpan49 = "Contributions-module--grid-span-4-9--GwIBH";
export var gridSpan510 = "Contributions-module--grid-span-5-10--z3WBh";
export var gridSpan511 = "Contributions-module--grid-span-5-11--xPZez";
export var gridSpan52 = "Contributions-module--grid-span-5-2--sY31+";
export var gridSpan53 = "Contributions-module--grid-span-5-3--qMYo7";
export var gridSpan54 = "Contributions-module--grid-span-5-4--tD8+f";
export var gridSpan55 = "Contributions-module--grid-span-5-5--+-SNO";
export var gridSpan56 = "Contributions-module--grid-span-5-6--nguIW";
export var gridSpan57 = "Contributions-module--grid-span-5-7--3a5GM";
export var gridSpan58 = "Contributions-module--grid-span-5-8--3ZZG7";
export var gridSpan59 = "Contributions-module--grid-span-5-9--PJJCP";
export var gridSpan610 = "Contributions-module--grid-span-6-10--HcyY8";
export var gridSpan611 = "Contributions-module--grid-span-6-11--OE3z1";
export var gridSpan62 = "Contributions-module--grid-span-6-2--H049-";
export var gridSpan63 = "Contributions-module--grid-span-6-3--qKMLb";
export var gridSpan64 = "Contributions-module--grid-span-6-4--nuR3b";
export var gridSpan65 = "Contributions-module--grid-span-6-5--EJZKj";
export var gridSpan66 = "Contributions-module--grid-span-6-6--i7bAF";
export var gridSpan67 = "Contributions-module--grid-span-6-7--fWBXM";
export var gridSpan68 = "Contributions-module--grid-span-6-8--gO5mZ";
export var gridSpan69 = "Contributions-module--grid-span-6-9--TuPWP";
export var gridSpan710 = "Contributions-module--grid-span-7-10--jQk7w";
export var gridSpan711 = "Contributions-module--grid-span-7-11--rg5pd";
export var gridSpan72 = "Contributions-module--grid-span-7-2--TTh3-";
export var gridSpan73 = "Contributions-module--grid-span-7-3--SEuTB";
export var gridSpan74 = "Contributions-module--grid-span-7-4--Oz1S7";
export var gridSpan75 = "Contributions-module--grid-span-7-5--VV86X";
export var gridSpan76 = "Contributions-module--grid-span-7-6--RjUvW";
export var gridSpan77 = "Contributions-module--grid-span-7-7--9Wn7T";
export var gridSpan78 = "Contributions-module--grid-span-7-8--YI40T";
export var gridSpan79 = "Contributions-module--grid-span-7-9--zsm5M";
export var gridSpan810 = "Contributions-module--grid-span-8-10--1NX6b";
export var gridSpan811 = "Contributions-module--grid-span-8-11--45FLY";
export var gridSpan82 = "Contributions-module--grid-span-8-2--FPoir";
export var gridSpan83 = "Contributions-module--grid-span-8-3--pkFT7";
export var gridSpan84 = "Contributions-module--grid-span-8-4--SGcwz";
export var gridSpan85 = "Contributions-module--grid-span-8-5--Q9aOO";
export var gridSpan86 = "Contributions-module--grid-span-8-6--6FqcO";
export var gridSpan87 = "Contributions-module--grid-span-8-7--7YaoY";
export var gridSpan88 = "Contributions-module--grid-span-8-8--KLP4K";
export var gridSpan89 = "Contributions-module--grid-span-8-9--3G8u4";
export var gridSpan910 = "Contributions-module--grid-span-9-10--z2zc1";
export var gridSpan911 = "Contributions-module--grid-span-9-11--g01-G";
export var gridSpan92 = "Contributions-module--grid-span-9-2--nHMfI";
export var gridSpan93 = "Contributions-module--grid-span-9-3--MngRa";
export var gridSpan94 = "Contributions-module--grid-span-9-4--NfWFQ";
export var gridSpan95 = "Contributions-module--grid-span-9-5--asQ45";
export var gridSpan96 = "Contributions-module--grid-span-9-6--SrBCz";
export var gridSpan97 = "Contributions-module--grid-span-9-7--gAK5b";
export var gridSpan98 = "Contributions-module--grid-span-9-8--6BqKm";
export var gridSpan99 = "Contributions-module--grid-span-9-9--fg39q";
export var textBreak = "Contributions-module--text-break--p1FsU";