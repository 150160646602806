import React from "react";
import { Button, ButtonVariant } from "brass-ui-kit";

import * as styles from "./Hero.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { APP_SIGNUP_URL } from "data/config";

const Hero = () => {
  return (
    <section className={styles.Hero}>
      <div className={styles.Hero_container}>
        <div className={styles.Hero_bg}>
          <StaticImage
            src="../../../assets/media/images/savings/hero-bg.png"
            alt="hero-bg"
            loading="eager"
          />
        </div>

        <div className={styles.Hero_content}>
          <span className={styles.Hero_content_meta}>BRASS SAVINGS</span>
          <h1 className={styles.Hero_content_title}>
            Get closer to your business goals
          </h1>
          <p className={styles.Hero_content_desc}>
            Set goals and save towards them. Track your progress, pause
            automated savings anytime. Let’s help you reach new heights with
            your business.
          </p>

          <Button
            variant={ButtonVariant.Primary}
            className={styles.Hero_content_btn}
            onClick={() => window.open(APP_SIGNUP_URL, "_target")}
          >
            Start saving
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
