import React from "react";
import {
  Hero,
  SavingsType,
  Contributions,
  Features,
  Testimonials,
  Qualities,
  Support,
} from "components/savings";
import { FaqWidget, GetStarted } from "components/design-system";
import { PageProps, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "components/SEO";
import pagesMeta from "data/pagesMeta";

import * as styles from "./Savings.module.scss";

const Savings: React.FC<PageProps> = ({ data }) => {
  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;

  return (
    <>
      <SEO {...pagesMeta.savings} />
      <Hero />
      <SavingsType />
      <Contributions />
      <Features />
      {/* <Testimonials /> */}
      <Qualities />
      <Support />
      <FaqWidget
        className={styles.Savings_faq}
        theme="light"
        content={pageData.faqContent}
      />

      <div className={styles.Savings_imgContainer}>
        <StaticImage
          src="../../assets/media/images/savings/image-banner.png"
          alt="banner"
          className={styles.Savings_img}
        />
      </div>

      <GetStarted
        theme="light"
        desc="Get started for free, in minutes. Start your business growth journey with us."
      />
    </>
  );
};

export const query = graphql`
  query SavingsPageQuery {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "savings" } } }
    ) {
      edges {
        node {
          frontmatter {
            faqContent {
              answer
              question
            }
            relatedPosts {
              link
              title
              excerpt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Savings;
