import React from "react";
import { Button, ButtonVariant } from "brass-ui-kit";
import { StaticImage } from "gatsby-plugin-image";
import appData from "../../../../app.json";

import * as styles from "./Support.module.scss";

const Support: React.FC = () => {
  return (
    <section className={styles.Support}>
      <h3 className={styles.Support_title}>
        Support and resources you need whenever you do
      </h3>

      <div className={styles.Support_content}>
        <StaticImage
          src="../../../assets/media/images/support.png"
          alt="support and resources"
          className={styles.Support_content_img}
        />

        <div className={styles.Support_content_info}>
          <div className={styles.Support_content_info_text}>
            <p>
              Get the support, guide and resources you need to operate a global
              business.
            </p>
            <p>
              We have set up a team of experts with relevant experience in
              international banking and global commerce to answers all your
              questions.
            </p>
            <p>Talk to us anytime.</p>
          </div>

          <Button
            variant={ButtonVariant.Primary}
            className={styles.Support_content_info_btn}
            onClick={() => window.open(`tel:${appData.phone}`, "_target")}
          >
            Contact us
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Support;
