// extracted by mini-css-extract-plugin
export var Qualities = "Qualities-module--Qualities---xWNv";
export var Qualities_content = "Qualities-module--Qualities_content--C2v5f";
export var Qualities_content_item = "Qualities-module--Qualities_content_item--WMRLC";
export var Qualities_content_item_media = "Qualities-module--Qualities_content_item_media--QtYjw";
export var Qualities_content_item_text = "Qualities-module--Qualities_content_item_text--AhP0d";
export var Qualities_mobile = "Qualities-module--Qualities_mobile--m6nPd";
export var Qualities_mobile_content = "Qualities-module--Qualities_mobile_content--tsmeZ";
export var Qualities_mobile_content_item = "Qualities-module--Qualities_mobile_content_item--7DLMq";
export var Qualities_mobile_content_item_media = "Qualities-module--Qualities_mobile_content_item_media--OO47k";
export var Qualities_mobile_content_item_text = "Qualities-module--Qualities_mobile_content_item_text--FI4OQ";
export var Qualities_mobile_selectors = "Qualities-module--Qualities_mobile_selectors--JErh7";
export var Qualities_mobile_selectors_selector = "Qualities-module--Qualities_mobile_selectors_selector--ENZVK";
export var Qualities_mobile_selectors_selector_active = "Qualities-module--Qualities_mobile_selectors_selector_active--tHq8W";
export var Qualities_title = "Qualities-module--Qualities_title--PXWGQ";
export var container = "Qualities-module--container--XONuP";
export var gatsbyImageWrapperConstrained = "Qualities-module--gatsby-image-wrapper-constrained--kNS-y";
export var gridSpan1010 = "Qualities-module--grid-span-10-10--qT494";
export var gridSpan1011 = "Qualities-module--grid-span-10-11--mMxf5";
export var gridSpan102 = "Qualities-module--grid-span-10-2--gRkGU";
export var gridSpan103 = "Qualities-module--grid-span-10-3--olQqi";
export var gridSpan104 = "Qualities-module--grid-span-10-4--ejRtV";
export var gridSpan105 = "Qualities-module--grid-span-10-5--ax4xk";
export var gridSpan106 = "Qualities-module--grid-span-10-6--zFw4d";
export var gridSpan107 = "Qualities-module--grid-span-10-7--oX2Rn";
export var gridSpan108 = "Qualities-module--grid-span-10-8--UzyER";
export var gridSpan109 = "Qualities-module--grid-span-10-9--iwY94";
export var gridSpan110 = "Qualities-module--grid-span-1-10--T7HvF";
export var gridSpan111 = "Qualities-module--grid-span-1-11--Flq1+";
export var gridSpan1110 = "Qualities-module--grid-span-11-10--+bjcd";
export var gridSpan1111 = "Qualities-module--grid-span-11-11--FKi6F";
export var gridSpan112 = "Qualities-module--grid-span-11-2--FDmxX";
export var gridSpan113 = "Qualities-module--grid-span-11-3--FNEAK";
export var gridSpan114 = "Qualities-module--grid-span-11-4--07JRv";
export var gridSpan115 = "Qualities-module--grid-span-11-5--vmi3F";
export var gridSpan116 = "Qualities-module--grid-span-11-6--oFWFB";
export var gridSpan117 = "Qualities-module--grid-span-11-7--NF0ID";
export var gridSpan118 = "Qualities-module--grid-span-11-8--0g5Il";
export var gridSpan119 = "Qualities-module--grid-span-11-9--wi87U";
export var gridSpan12 = "Qualities-module--grid-span-1-2--UgtHw";
export var gridSpan1210 = "Qualities-module--grid-span-12-10--RWalO";
export var gridSpan1211 = "Qualities-module--grid-span-12-11--Mw9Um";
export var gridSpan122 = "Qualities-module--grid-span-12-2--NTqQl";
export var gridSpan123 = "Qualities-module--grid-span-12-3--2NQCt";
export var gridSpan124 = "Qualities-module--grid-span-12-4--nkgAT";
export var gridSpan125 = "Qualities-module--grid-span-12-5--C-89z";
export var gridSpan126 = "Qualities-module--grid-span-12-6--At0Kh";
export var gridSpan127 = "Qualities-module--grid-span-12-7--2W3+r";
export var gridSpan128 = "Qualities-module--grid-span-12-8--urQb9";
export var gridSpan129 = "Qualities-module--grid-span-12-9--h6+NJ";
export var gridSpan13 = "Qualities-module--grid-span-1-3--Qoijp";
export var gridSpan14 = "Qualities-module--grid-span-1-4--Ha5TD";
export var gridSpan15 = "Qualities-module--grid-span-1-5--0Q9mR";
export var gridSpan16 = "Qualities-module--grid-span-1-6--+WRQn";
export var gridSpan17 = "Qualities-module--grid-span-1-7--jpnYQ";
export var gridSpan18 = "Qualities-module--grid-span-1-8--9BLoI";
export var gridSpan19 = "Qualities-module--grid-span-1-9--Hvozh";
export var gridSpan210 = "Qualities-module--grid-span-2-10--z4P1W";
export var gridSpan211 = "Qualities-module--grid-span-2-11--3x8HV";
export var gridSpan22 = "Qualities-module--grid-span-2-2--1PVyZ";
export var gridSpan23 = "Qualities-module--grid-span-2-3--e-LeB";
export var gridSpan24 = "Qualities-module--grid-span-2-4--N8Ow9";
export var gridSpan25 = "Qualities-module--grid-span-2-5--vkzff";
export var gridSpan26 = "Qualities-module--grid-span-2-6--rOa0G";
export var gridSpan27 = "Qualities-module--grid-span-2-7--fKm6P";
export var gridSpan28 = "Qualities-module--grid-span-2-8--XjQcs";
export var gridSpan29 = "Qualities-module--grid-span-2-9--alnJo";
export var gridSpan310 = "Qualities-module--grid-span-3-10--9ymjc";
export var gridSpan311 = "Qualities-module--grid-span-3-11--93lgJ";
export var gridSpan32 = "Qualities-module--grid-span-3-2--3OPdb";
export var gridSpan33 = "Qualities-module--grid-span-3-3--KpckX";
export var gridSpan34 = "Qualities-module--grid-span-3-4--8S7AS";
export var gridSpan35 = "Qualities-module--grid-span-3-5--9O9ED";
export var gridSpan36 = "Qualities-module--grid-span-3-6--zf8Gd";
export var gridSpan37 = "Qualities-module--grid-span-3-7--B2SYJ";
export var gridSpan38 = "Qualities-module--grid-span-3-8--eL39U";
export var gridSpan39 = "Qualities-module--grid-span-3-9---w+Ia";
export var gridSpan410 = "Qualities-module--grid-span-4-10--IW3lo";
export var gridSpan411 = "Qualities-module--grid-span-4-11--TaOK5";
export var gridSpan42 = "Qualities-module--grid-span-4-2--vPOvx";
export var gridSpan43 = "Qualities-module--grid-span-4-3--M3cqU";
export var gridSpan44 = "Qualities-module--grid-span-4-4--wbJyT";
export var gridSpan45 = "Qualities-module--grid-span-4-5--O-5eF";
export var gridSpan46 = "Qualities-module--grid-span-4-6--rDhSv";
export var gridSpan47 = "Qualities-module--grid-span-4-7--mD9Yv";
export var gridSpan48 = "Qualities-module--grid-span-4-8--B1C88";
export var gridSpan49 = "Qualities-module--grid-span-4-9--RxJKZ";
export var gridSpan510 = "Qualities-module--grid-span-5-10--qawVA";
export var gridSpan511 = "Qualities-module--grid-span-5-11--bDCKX";
export var gridSpan52 = "Qualities-module--grid-span-5-2--TjkCF";
export var gridSpan53 = "Qualities-module--grid-span-5-3--hzvld";
export var gridSpan54 = "Qualities-module--grid-span-5-4--muN-2";
export var gridSpan55 = "Qualities-module--grid-span-5-5--P4pWA";
export var gridSpan56 = "Qualities-module--grid-span-5-6--d8AXA";
export var gridSpan57 = "Qualities-module--grid-span-5-7--gxrbm";
export var gridSpan58 = "Qualities-module--grid-span-5-8--irYY8";
export var gridSpan59 = "Qualities-module--grid-span-5-9--A442D";
export var gridSpan610 = "Qualities-module--grid-span-6-10--ePOEu";
export var gridSpan611 = "Qualities-module--grid-span-6-11--hHHNR";
export var gridSpan62 = "Qualities-module--grid-span-6-2--Pn+gB";
export var gridSpan63 = "Qualities-module--grid-span-6-3--6b+ED";
export var gridSpan64 = "Qualities-module--grid-span-6-4--VAamx";
export var gridSpan65 = "Qualities-module--grid-span-6-5--QDN+C";
export var gridSpan66 = "Qualities-module--grid-span-6-6--GTa3m";
export var gridSpan67 = "Qualities-module--grid-span-6-7--gtY0c";
export var gridSpan68 = "Qualities-module--grid-span-6-8--j5bot";
export var gridSpan69 = "Qualities-module--grid-span-6-9--+2piK";
export var gridSpan710 = "Qualities-module--grid-span-7-10--FGv-a";
export var gridSpan711 = "Qualities-module--grid-span-7-11--aJY26";
export var gridSpan72 = "Qualities-module--grid-span-7-2--ZoQUN";
export var gridSpan73 = "Qualities-module--grid-span-7-3--Aashu";
export var gridSpan74 = "Qualities-module--grid-span-7-4--Tb2Ld";
export var gridSpan75 = "Qualities-module--grid-span-7-5--qIwTq";
export var gridSpan76 = "Qualities-module--grid-span-7-6--mzVXc";
export var gridSpan77 = "Qualities-module--grid-span-7-7--BWr92";
export var gridSpan78 = "Qualities-module--grid-span-7-8--66FVT";
export var gridSpan79 = "Qualities-module--grid-span-7-9--Hrk-J";
export var gridSpan810 = "Qualities-module--grid-span-8-10--bikAR";
export var gridSpan811 = "Qualities-module--grid-span-8-11--uKUgE";
export var gridSpan82 = "Qualities-module--grid-span-8-2--NKrj4";
export var gridSpan83 = "Qualities-module--grid-span-8-3--rv2hr";
export var gridSpan84 = "Qualities-module--grid-span-8-4--EwqkW";
export var gridSpan85 = "Qualities-module--grid-span-8-5--ZHk2x";
export var gridSpan86 = "Qualities-module--grid-span-8-6--0F1uI";
export var gridSpan87 = "Qualities-module--grid-span-8-7--3PLhJ";
export var gridSpan88 = "Qualities-module--grid-span-8-8--od7J0";
export var gridSpan89 = "Qualities-module--grid-span-8-9--0yA8v";
export var gridSpan910 = "Qualities-module--grid-span-9-10--Ju+ao";
export var gridSpan911 = "Qualities-module--grid-span-9-11--htwVz";
export var gridSpan92 = "Qualities-module--grid-span-9-2--yNQEC";
export var gridSpan93 = "Qualities-module--grid-span-9-3--aAIJE";
export var gridSpan94 = "Qualities-module--grid-span-9-4--SmnHV";
export var gridSpan95 = "Qualities-module--grid-span-9-5--8PujK";
export var gridSpan96 = "Qualities-module--grid-span-9-6--xBwOt";
export var gridSpan97 = "Qualities-module--grid-span-9-7--nfC1N";
export var gridSpan98 = "Qualities-module--grid-span-9-8--p1kg5";
export var gridSpan99 = "Qualities-module--grid-span-9-9--L99GV";
export var textBreak = "Qualities-module--text-break--jNjpV";