import React from "react";
import { uniqueId } from "lodash";
import cx from "classnames";

import SavingsInfo from "./SavingsInfo";
import { SAVINGS_DATA } from "data/pages";
import SavingsTypeFlexibleImg from "assets/media/images/savings/savings-type-flexible.jpg";
import SavingsTypeFixedImg from "assets/media/images/savings/savings-type-fixed.jpg";

import * as styles from "./SavingsType.module.scss";

const SavingsType = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const { TARGET_SAVINGS_DATA } = SAVINGS_DATA;
  return (
    <section className={styles.SavingsType}>
      <div className={styles.SavingsType_content}>
        <SavingsInfo
          meta="BRASS FIXED DEPOSITS"
          title={
            <>
              Grow your money daily. <br />
              Earn up to 18% P.A. with fixed deposits
            </>
          }
          desc="Earn up to 18% per annum paid daily when you create a fixed deposit with Brass."
          img={SavingsTypeFixedImg}
        />

        <div className={styles.SavingsType_content_type}>
          <div className={styles.SavingsType_content_type_info}>
            <span className={styles.SavingsType_content_type_info_meta}>
              BRASS TARGET SAVINGS
            </span>

            <h1 className={styles.SavingsType_content_type_info_title}>
              Goal driven business savings
            </h1>

            <p className={styles.SavingsType_content_type_info_desc}>
              We’ll help you develop a habit of saving with savings plans
              tailored to meet your business goals.
            </p>
          </div>

          <div className={styles.SavingsType_content_type_details}>
            {TARGET_SAVINGS_DATA.map(({ text, img }) => (
              <div
                key={uniqueId("target-savings")}
                className={styles.SavingsType_content_type_details_item}
              >
                <p
                  className={styles.SavingsType_content_type_details_item_text}
                >
                  {text}
                </p>

                <img
                  src={img}
                  alt={text}
                  className={styles.SavingsType_content_type_details_item_img}
                />
              </div>
            ))}
          </div>

          <div className={styles.SavingsType_content_type_mobile}>
            <div className={styles.SavingsType_content_type_mobile_item}>
              <p className={styles.SavingsType_content_type_mobile_item_text}>
                {TARGET_SAVINGS_DATA[activeIndex].text}
              </p>

              <img
                src={TARGET_SAVINGS_DATA[activeIndex].img}
                alt={TARGET_SAVINGS_DATA[activeIndex].text}
                className={cx(styles.SavingsType_content_type_mobile_item_img, [
                  styles[
                    `SavingsType_content_type_mobile_item_img_${TARGET_SAVINGS_DATA[activeIndex].className}`
                  ],
                ])}
              />
            </div>

            <div className={styles.SavingsType_content_type_mobile_selectors}>
              {TARGET_SAVINGS_DATA.map((_, index) => (
                <div
                  key={uniqueId("target-savings-mobile")}
                  onClick={() => setActiveIndex(index)}
                  className={cx(
                    styles.SavingsType_content_type_mobile_selectors_selector,
                    {
                      [styles.SavingsType_content_type_mobile_selectors_selector_active]:
                        activeIndex === index,
                    }
                  )}
                />
              ))}
            </div>
          </div>
        </div>

        <SavingsInfo
          meta="BRASS FLEXIBLE SAVINGS"
          title="5.5% interest P.A. paid on your average monthly flexible savings balance."
          desc="Save whenever and however you like, and paid interest on your average monthly balance."
          img={SavingsTypeFlexibleImg}
        />
      </div>
    </section>
  );
};

export default SavingsType;
