import React from "react";
import cx from "classnames";
import { SAVINGS_DATA } from "data/pages";
import { uniqueId } from "lodash";

import * as styles from "./Qualities.module.scss";

const Qualities = () => {
  const { QUALITIES } = SAVINGS_DATA;

  const maxLength = 3;

  const [activeIndex, setActiveIndex] = React.useState(0);

  const mobileQualities = QUALITIES.slice(
    activeIndex * maxLength,
    (activeIndex + 1) * maxLength
  );

  const lenghtOfSelectors = Math.ceil(QUALITIES.length / maxLength);

  return (
    <section className={styles.Qualities}>
      <h1 className={styles.Qualities_title}>A lot more than just savings</h1>

      <div className={styles.Qualities_content}>
        {QUALITIES.map(({ text, gif }) => (
          <div
            key={uniqueId("qualities-")}
            className={styles.Qualities_content_item}
          >
            <div className={styles.Qualities_content_item_media}>
              <img src={gif} alt="qualities" />
            </div>

            <p className={styles.Qualities_content_item_text}>{text}</p>
          </div>
        ))}
      </div>

      <div className={styles.Qualities_mobile}>
        <div className={styles.Qualities_mobile_content}>
          {mobileQualities.map(({ text, gif }) => (
            <div
              key={uniqueId("qualities-")}
              className={styles.Qualities_mobile_content_item}
            >
              <div className={styles.Qualities_mobile_content_item_media}>
                <img src={gif} alt="qualities" />
              </div>

              <p className={styles.Qualities_mobile_content_item_text}>
                {text}
              </p>
            </div>
          ))}
        </div>

        <div className={styles.Qualities_mobile_selectors}>
          {Array.from({ length: lenghtOfSelectors }).map((_, index) => (
            <div
              key={uniqueId("qualities-mobile-")}
              className={cx(styles.Qualities_mobile_selectors_selector, {
                [styles.Qualities_mobile_selectors_selector_active]:
                  index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Qualities;
