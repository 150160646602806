// extracted by mini-css-extract-plugin
export var SavingsType = "SavingsType-module--SavingsType--KIiMk";
export var SavingsType_content = "SavingsType-module--SavingsType_content--NO0nK";
export var SavingsType_content_type = "SavingsType-module--SavingsType_content_type--DdqBP";
export var SavingsType_content_type_details = "SavingsType-module--SavingsType_content_type_details--k3Wv9";
export var SavingsType_content_type_details_item = "SavingsType-module--SavingsType_content_type_details_item--rl-Zy";
export var SavingsType_content_type_details_item_img = "SavingsType-module--SavingsType_content_type_details_item_img--tY4Gs";
export var SavingsType_content_type_details_item_text = "SavingsType-module--SavingsType_content_type_details_item_text--dYzgB";
export var SavingsType_content_type_info = "SavingsType-module--SavingsType_content_type_info--serZe";
export var SavingsType_content_type_info_desc = "SavingsType-module--SavingsType_content_type_info_desc--va5v5";
export var SavingsType_content_type_info_meta = "SavingsType-module--SavingsType_content_type_info_meta--CrgJT";
export var SavingsType_content_type_info_title = "SavingsType-module--SavingsType_content_type_info_title--c9K+u";
export var SavingsType_content_type_mobile = "SavingsType-module--SavingsType_content_type_mobile--z+JnW";
export var SavingsType_content_type_mobile_item = "SavingsType-module--SavingsType_content_type_mobile_item--efdMS";
export var SavingsType_content_type_mobile_item_img = "SavingsType-module--SavingsType_content_type_mobile_item_img--iU097";
export var SavingsType_content_type_mobile_item_img_rainy = "SavingsType-module--SavingsType_content_type_mobile_item_img_rainy--r2TNP";
export var SavingsType_content_type_mobile_item_img_store = "SavingsType-module--SavingsType_content_type_mobile_item_img_store--jcF+l";
export var SavingsType_content_type_mobile_item_img_website = "SavingsType-module--SavingsType_content_type_mobile_item_img_website--eNhDz";
export var SavingsType_content_type_mobile_item_text = "SavingsType-module--SavingsType_content_type_mobile_item_text--3PPNI";
export var SavingsType_content_type_mobile_selectors = "SavingsType-module--SavingsType_content_type_mobile_selectors--gFeXD";
export var SavingsType_content_type_mobile_selectors_selector = "SavingsType-module--SavingsType_content_type_mobile_selectors_selector--+Mzns";
export var SavingsType_content_type_mobile_selectors_selector_active = "SavingsType-module--SavingsType_content_type_mobile_selectors_selector_active--Cb-ii";
export var SavingsType_info = "SavingsType-module--SavingsType_info--SfxMs";
export var SavingsType_info_content = "SavingsType-module--SavingsType_info_content--3pf-v";
export var SavingsType_info_content_desc = "SavingsType-module--SavingsType_info_content_desc--mRFcL";
export var SavingsType_info_content_img = "SavingsType-module--SavingsType_info_content_img--qOCK2";
export var SavingsType_info_meta = "SavingsType-module--SavingsType_info_meta--84apo";
export var SavingsType_info_title = "SavingsType-module--SavingsType_info_title--6emD7";
export var container = "SavingsType-module--container--hMWwy";
export var gatsbyImageWrapperConstrained = "SavingsType-module--gatsby-image-wrapper-constrained--V6VEc";
export var gridSpan1010 = "SavingsType-module--grid-span-10-10--mAiF7";
export var gridSpan1011 = "SavingsType-module--grid-span-10-11--fKOCM";
export var gridSpan102 = "SavingsType-module--grid-span-10-2--l8KhJ";
export var gridSpan103 = "SavingsType-module--grid-span-10-3--ch-Mm";
export var gridSpan104 = "SavingsType-module--grid-span-10-4--iyKwR";
export var gridSpan105 = "SavingsType-module--grid-span-10-5--+n5wE";
export var gridSpan106 = "SavingsType-module--grid-span-10-6--pgG3G";
export var gridSpan107 = "SavingsType-module--grid-span-10-7--o4Tke";
export var gridSpan108 = "SavingsType-module--grid-span-10-8--T73VI";
export var gridSpan109 = "SavingsType-module--grid-span-10-9--JshfB";
export var gridSpan110 = "SavingsType-module--grid-span-1-10--f8nMB";
export var gridSpan111 = "SavingsType-module--grid-span-1-11--vIkng";
export var gridSpan1110 = "SavingsType-module--grid-span-11-10--fQvV1";
export var gridSpan1111 = "SavingsType-module--grid-span-11-11--3P7MX";
export var gridSpan112 = "SavingsType-module--grid-span-11-2--YT-s4";
export var gridSpan113 = "SavingsType-module--grid-span-11-3--iGZAB";
export var gridSpan114 = "SavingsType-module--grid-span-11-4---IjaA";
export var gridSpan115 = "SavingsType-module--grid-span-11-5--M31ch";
export var gridSpan116 = "SavingsType-module--grid-span-11-6--ZXGsw";
export var gridSpan117 = "SavingsType-module--grid-span-11-7--AdROB";
export var gridSpan118 = "SavingsType-module--grid-span-11-8--5Bkla";
export var gridSpan119 = "SavingsType-module--grid-span-11-9--cSIYv";
export var gridSpan12 = "SavingsType-module--grid-span-1-2--kThZ2";
export var gridSpan1210 = "SavingsType-module--grid-span-12-10--DVNU7";
export var gridSpan1211 = "SavingsType-module--grid-span-12-11--TIV6G";
export var gridSpan122 = "SavingsType-module--grid-span-12-2--TU8U-";
export var gridSpan123 = "SavingsType-module--grid-span-12-3--qyae1";
export var gridSpan124 = "SavingsType-module--grid-span-12-4--zZfP7";
export var gridSpan125 = "SavingsType-module--grid-span-12-5--93w5m";
export var gridSpan126 = "SavingsType-module--grid-span-12-6--1ge3B";
export var gridSpan127 = "SavingsType-module--grid-span-12-7--Ihqme";
export var gridSpan128 = "SavingsType-module--grid-span-12-8--aib6T";
export var gridSpan129 = "SavingsType-module--grid-span-12-9--TpDWm";
export var gridSpan13 = "SavingsType-module--grid-span-1-3--Sh0pQ";
export var gridSpan14 = "SavingsType-module--grid-span-1-4--rOkeh";
export var gridSpan15 = "SavingsType-module--grid-span-1-5--NyYpB";
export var gridSpan16 = "SavingsType-module--grid-span-1-6--SmOTG";
export var gridSpan17 = "SavingsType-module--grid-span-1-7--bYQUZ";
export var gridSpan18 = "SavingsType-module--grid-span-1-8--T08X7";
export var gridSpan19 = "SavingsType-module--grid-span-1-9--ILdgw";
export var gridSpan210 = "SavingsType-module--grid-span-2-10--AluRa";
export var gridSpan211 = "SavingsType-module--grid-span-2-11--Y8cvH";
export var gridSpan22 = "SavingsType-module--grid-span-2-2--xiuFI";
export var gridSpan23 = "SavingsType-module--grid-span-2-3--1D3VP";
export var gridSpan24 = "SavingsType-module--grid-span-2-4--iyIct";
export var gridSpan25 = "SavingsType-module--grid-span-2-5--yafW2";
export var gridSpan26 = "SavingsType-module--grid-span-2-6--susS4";
export var gridSpan27 = "SavingsType-module--grid-span-2-7--wdOtm";
export var gridSpan28 = "SavingsType-module--grid-span-2-8--vLhaP";
export var gridSpan29 = "SavingsType-module--grid-span-2-9--0kOhY";
export var gridSpan310 = "SavingsType-module--grid-span-3-10--Mf2LU";
export var gridSpan311 = "SavingsType-module--grid-span-3-11--aqEok";
export var gridSpan32 = "SavingsType-module--grid-span-3-2--HGlCZ";
export var gridSpan33 = "SavingsType-module--grid-span-3-3--sULHp";
export var gridSpan34 = "SavingsType-module--grid-span-3-4--WU-mK";
export var gridSpan35 = "SavingsType-module--grid-span-3-5--6xYiH";
export var gridSpan36 = "SavingsType-module--grid-span-3-6--BQFBw";
export var gridSpan37 = "SavingsType-module--grid-span-3-7--SvKCH";
export var gridSpan38 = "SavingsType-module--grid-span-3-8--7uXCO";
export var gridSpan39 = "SavingsType-module--grid-span-3-9--vYqx3";
export var gridSpan410 = "SavingsType-module--grid-span-4-10--SubcW";
export var gridSpan411 = "SavingsType-module--grid-span-4-11--5GmoI";
export var gridSpan42 = "SavingsType-module--grid-span-4-2--SyDzw";
export var gridSpan43 = "SavingsType-module--grid-span-4-3---cKVC";
export var gridSpan44 = "SavingsType-module--grid-span-4-4--QACr1";
export var gridSpan45 = "SavingsType-module--grid-span-4-5--hmchE";
export var gridSpan46 = "SavingsType-module--grid-span-4-6--IcMA9";
export var gridSpan47 = "SavingsType-module--grid-span-4-7--EbYLb";
export var gridSpan48 = "SavingsType-module--grid-span-4-8--Ppt0y";
export var gridSpan49 = "SavingsType-module--grid-span-4-9--50dHC";
export var gridSpan510 = "SavingsType-module--grid-span-5-10--oYFh9";
export var gridSpan511 = "SavingsType-module--grid-span-5-11--opqgb";
export var gridSpan52 = "SavingsType-module--grid-span-5-2--XCmOG";
export var gridSpan53 = "SavingsType-module--grid-span-5-3--jcYT4";
export var gridSpan54 = "SavingsType-module--grid-span-5-4--ebSI7";
export var gridSpan55 = "SavingsType-module--grid-span-5-5--DnKXb";
export var gridSpan56 = "SavingsType-module--grid-span-5-6--o65c-";
export var gridSpan57 = "SavingsType-module--grid-span-5-7--cEIHu";
export var gridSpan58 = "SavingsType-module--grid-span-5-8--r16mX";
export var gridSpan59 = "SavingsType-module--grid-span-5-9--55NDl";
export var gridSpan610 = "SavingsType-module--grid-span-6-10--2fWLL";
export var gridSpan611 = "SavingsType-module--grid-span-6-11--cHfUD";
export var gridSpan62 = "SavingsType-module--grid-span-6-2--M9rXY";
export var gridSpan63 = "SavingsType-module--grid-span-6-3--RI97s";
export var gridSpan64 = "SavingsType-module--grid-span-6-4--psXLs";
export var gridSpan65 = "SavingsType-module--grid-span-6-5--Huwri";
export var gridSpan66 = "SavingsType-module--grid-span-6-6--jpSBi";
export var gridSpan67 = "SavingsType-module--grid-span-6-7--dnYTi";
export var gridSpan68 = "SavingsType-module--grid-span-6-8--NsadF";
export var gridSpan69 = "SavingsType-module--grid-span-6-9--dpwKL";
export var gridSpan710 = "SavingsType-module--grid-span-7-10--jik9L";
export var gridSpan711 = "SavingsType-module--grid-span-7-11--O9YWN";
export var gridSpan72 = "SavingsType-module--grid-span-7-2--1vxxR";
export var gridSpan73 = "SavingsType-module--grid-span-7-3--u+Bxy";
export var gridSpan74 = "SavingsType-module--grid-span-7-4--2f5RP";
export var gridSpan75 = "SavingsType-module--grid-span-7-5--dP83T";
export var gridSpan76 = "SavingsType-module--grid-span-7-6--nfumP";
export var gridSpan77 = "SavingsType-module--grid-span-7-7--xMEvQ";
export var gridSpan78 = "SavingsType-module--grid-span-7-8--hZ6jo";
export var gridSpan79 = "SavingsType-module--grid-span-7-9--UoN8n";
export var gridSpan810 = "SavingsType-module--grid-span-8-10--yOtmP";
export var gridSpan811 = "SavingsType-module--grid-span-8-11--tmLq3";
export var gridSpan82 = "SavingsType-module--grid-span-8-2--yr0Bf";
export var gridSpan83 = "SavingsType-module--grid-span-8-3--PjraM";
export var gridSpan84 = "SavingsType-module--grid-span-8-4--7VW5r";
export var gridSpan85 = "SavingsType-module--grid-span-8-5--7B3aU";
export var gridSpan86 = "SavingsType-module--grid-span-8-6--pHYX8";
export var gridSpan87 = "SavingsType-module--grid-span-8-7--AMgw9";
export var gridSpan88 = "SavingsType-module--grid-span-8-8--oqS2d";
export var gridSpan89 = "SavingsType-module--grid-span-8-9--PHeJp";
export var gridSpan910 = "SavingsType-module--grid-span-9-10--b6otG";
export var gridSpan911 = "SavingsType-module--grid-span-9-11--DuvSK";
export var gridSpan92 = "SavingsType-module--grid-span-9-2--yVSpQ";
export var gridSpan93 = "SavingsType-module--grid-span-9-3--6Ml2F";
export var gridSpan94 = "SavingsType-module--grid-span-9-4--+peoM";
export var gridSpan95 = "SavingsType-module--grid-span-9-5--m6COU";
export var gridSpan96 = "SavingsType-module--grid-span-9-6--Uxm8J";
export var gridSpan97 = "SavingsType-module--grid-span-9-7--zX1mn";
export var gridSpan98 = "SavingsType-module--grid-span-9-8--RFvRS";
export var gridSpan99 = "SavingsType-module--grid-span-9-9--y+cPv";
export var slide = "SavingsType-module--slide--hzTZz";
export var textBreak = "SavingsType-module--text-break--hJ5L3";