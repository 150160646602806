// extracted by mini-css-extract-plugin
export var Features = "Features-module--Features--gzFdH";
export var Features_contents = "Features-module--Features_contents--Ue83W";
export var Features_contents_item = "Features-module--Features_contents_item--8UMXc";
export var Features_contents_item_info = "Features-module--Features_contents_item_info--xQ3W8";
export var Features_contents_item_info_details = "Features-module--Features_contents_item_info_details--gSQ52";
export var Features_contents_item_info_details_features = "Features-module--Features_contents_item_info_details_features--+M2Ol";
export var Features_contents_item_info_details_label = "Features-module--Features_contents_item_info_details_label--9PMz1";
export var Features_contents_item_info_top = "Features-module--Features_contents_item_info_top--N2fUi";
export var Features_contents_item_info_top_desc = "Features-module--Features_contents_item_info_top_desc--d82EI";
export var Features_contents_item_info_top_title = "Features-module--Features_contents_item_info_top_title--ecllm";
export var Features_contents_item_media = "Features-module--Features_contents_item_media--PYjNo";
export var Features_contents_item_media_info = "Features-module--Features_contents_item_media_info--JXxoW";
export var Features_contents_item_media_info_interest = "Features-module--Features_contents_item_media_info_interest--K0RNX";
export var Features_contents_item_media_info_rate = "Features-module--Features_contents_item_media_info_rate--dNiRm";
export var Features_contents_item_media_info_top = "Features-module--Features_contents_item_media_info_top--IJAtE";
export var Features_contents_item_media_info_top_name = "Features-module--Features_contents_item_media_info_top_name--QW67t";
export var Features_contents_item_media_info_top_type = "Features-module--Features_contents_item_media_info_top_type--6p6eh";
export var Features_contents_item_title = "Features-module--Features_contents_item_title--0HX9J";
export var Features_header = "Features-module--Features_header--lb8bm";
export var Features_header_desc = "Features-module--Features_header_desc--DStJe";
export var Features_header_title = "Features-module--Features_header_title--iFwXF";
export var container = "Features-module--container--jYjps";
export var gatsbyImageWrapperConstrained = "Features-module--gatsby-image-wrapper-constrained--dXy-O";
export var gridSpan1010 = "Features-module--grid-span-10-10--my6Nw";
export var gridSpan1011 = "Features-module--grid-span-10-11--Ry6a0";
export var gridSpan102 = "Features-module--grid-span-10-2--gZivm";
export var gridSpan103 = "Features-module--grid-span-10-3--Eq0ii";
export var gridSpan104 = "Features-module--grid-span-10-4--MUMeL";
export var gridSpan105 = "Features-module--grid-span-10-5--kD2Cs";
export var gridSpan106 = "Features-module--grid-span-10-6--bA0YJ";
export var gridSpan107 = "Features-module--grid-span-10-7--dEbqX";
export var gridSpan108 = "Features-module--grid-span-10-8--JUsho";
export var gridSpan109 = "Features-module--grid-span-10-9--aucUw";
export var gridSpan110 = "Features-module--grid-span-1-10--S6hzZ";
export var gridSpan111 = "Features-module--grid-span-1-11--5TRKf";
export var gridSpan1110 = "Features-module--grid-span-11-10--LqH9L";
export var gridSpan1111 = "Features-module--grid-span-11-11--PGzBt";
export var gridSpan112 = "Features-module--grid-span-11-2--I5JbZ";
export var gridSpan113 = "Features-module--grid-span-11-3--iR8oV";
export var gridSpan114 = "Features-module--grid-span-11-4--jfN4E";
export var gridSpan115 = "Features-module--grid-span-11-5--BXj3W";
export var gridSpan116 = "Features-module--grid-span-11-6--7A2ZM";
export var gridSpan117 = "Features-module--grid-span-11-7--Ekmmx";
export var gridSpan118 = "Features-module--grid-span-11-8--1mwWB";
export var gridSpan119 = "Features-module--grid-span-11-9--KghEx";
export var gridSpan12 = "Features-module--grid-span-1-2--pM7xg";
export var gridSpan1210 = "Features-module--grid-span-12-10--DGEuU";
export var gridSpan1211 = "Features-module--grid-span-12-11--UyYZc";
export var gridSpan122 = "Features-module--grid-span-12-2--s+F3b";
export var gridSpan123 = "Features-module--grid-span-12-3--OBxym";
export var gridSpan124 = "Features-module--grid-span-12-4--b+gNF";
export var gridSpan125 = "Features-module--grid-span-12-5--KYIIT";
export var gridSpan126 = "Features-module--grid-span-12-6--gb-2G";
export var gridSpan127 = "Features-module--grid-span-12-7--uxEV8";
export var gridSpan128 = "Features-module--grid-span-12-8--lPD4H";
export var gridSpan129 = "Features-module--grid-span-12-9--leSB3";
export var gridSpan13 = "Features-module--grid-span-1-3--GLE1o";
export var gridSpan14 = "Features-module--grid-span-1-4--xxuCH";
export var gridSpan15 = "Features-module--grid-span-1-5--1W-Iy";
export var gridSpan16 = "Features-module--grid-span-1-6--3PB5F";
export var gridSpan17 = "Features-module--grid-span-1-7--S7afO";
export var gridSpan18 = "Features-module--grid-span-1-8--eGK1y";
export var gridSpan19 = "Features-module--grid-span-1-9--020-u";
export var gridSpan210 = "Features-module--grid-span-2-10--BPWtP";
export var gridSpan211 = "Features-module--grid-span-2-11--ZqHKJ";
export var gridSpan22 = "Features-module--grid-span-2-2--xO56c";
export var gridSpan23 = "Features-module--grid-span-2-3--D6sNa";
export var gridSpan24 = "Features-module--grid-span-2-4--G8JSR";
export var gridSpan25 = "Features-module--grid-span-2-5--hWXFJ";
export var gridSpan26 = "Features-module--grid-span-2-6--CzAOj";
export var gridSpan27 = "Features-module--grid-span-2-7--5WVUt";
export var gridSpan28 = "Features-module--grid-span-2-8--2Chdm";
export var gridSpan29 = "Features-module--grid-span-2-9--wo-ri";
export var gridSpan310 = "Features-module--grid-span-3-10--02Ez5";
export var gridSpan311 = "Features-module--grid-span-3-11--FengG";
export var gridSpan32 = "Features-module--grid-span-3-2--y0nFo";
export var gridSpan33 = "Features-module--grid-span-3-3--mXl2p";
export var gridSpan34 = "Features-module--grid-span-3-4--Q4AYO";
export var gridSpan35 = "Features-module--grid-span-3-5--QLkFO";
export var gridSpan36 = "Features-module--grid-span-3-6--9h1wc";
export var gridSpan37 = "Features-module--grid-span-3-7--qDqXy";
export var gridSpan38 = "Features-module--grid-span-3-8--iOBhp";
export var gridSpan39 = "Features-module--grid-span-3-9--9Il2P";
export var gridSpan410 = "Features-module--grid-span-4-10--0fHqP";
export var gridSpan411 = "Features-module--grid-span-4-11--TiolF";
export var gridSpan42 = "Features-module--grid-span-4-2--TNI-V";
export var gridSpan43 = "Features-module--grid-span-4-3--q4hEa";
export var gridSpan44 = "Features-module--grid-span-4-4--frnet";
export var gridSpan45 = "Features-module--grid-span-4-5--COZhg";
export var gridSpan46 = "Features-module--grid-span-4-6--lP7pm";
export var gridSpan47 = "Features-module--grid-span-4-7--0DbOj";
export var gridSpan48 = "Features-module--grid-span-4-8--Ixh1P";
export var gridSpan49 = "Features-module--grid-span-4-9--VJYF7";
export var gridSpan510 = "Features-module--grid-span-5-10--Onmzm";
export var gridSpan511 = "Features-module--grid-span-5-11--eFUK5";
export var gridSpan52 = "Features-module--grid-span-5-2--0GcP8";
export var gridSpan53 = "Features-module--grid-span-5-3--JpCZE";
export var gridSpan54 = "Features-module--grid-span-5-4--g9dNe";
export var gridSpan55 = "Features-module--grid-span-5-5--wYdBf";
export var gridSpan56 = "Features-module--grid-span-5-6--V5Tbv";
export var gridSpan57 = "Features-module--grid-span-5-7--P1nR3";
export var gridSpan58 = "Features-module--grid-span-5-8--FZlzp";
export var gridSpan59 = "Features-module--grid-span-5-9--cYO9X";
export var gridSpan610 = "Features-module--grid-span-6-10--kk88u";
export var gridSpan611 = "Features-module--grid-span-6-11--V9Jch";
export var gridSpan62 = "Features-module--grid-span-6-2--+SMr0";
export var gridSpan63 = "Features-module--grid-span-6-3--3LY3V";
export var gridSpan64 = "Features-module--grid-span-6-4--KJ65Y";
export var gridSpan65 = "Features-module--grid-span-6-5--tKfOV";
export var gridSpan66 = "Features-module--grid-span-6-6--TmIpr";
export var gridSpan67 = "Features-module--grid-span-6-7--Q0+BX";
export var gridSpan68 = "Features-module--grid-span-6-8--pqWng";
export var gridSpan69 = "Features-module--grid-span-6-9--NkQK2";
export var gridSpan710 = "Features-module--grid-span-7-10--7B9j3";
export var gridSpan711 = "Features-module--grid-span-7-11--USxwa";
export var gridSpan72 = "Features-module--grid-span-7-2--fAM1I";
export var gridSpan73 = "Features-module--grid-span-7-3--9Kmwj";
export var gridSpan74 = "Features-module--grid-span-7-4--i0gQV";
export var gridSpan75 = "Features-module--grid-span-7-5--6FMiQ";
export var gridSpan76 = "Features-module--grid-span-7-6--hA0Qi";
export var gridSpan77 = "Features-module--grid-span-7-7--l0247";
export var gridSpan78 = "Features-module--grid-span-7-8--npwHD";
export var gridSpan79 = "Features-module--grid-span-7-9--2lcXr";
export var gridSpan810 = "Features-module--grid-span-8-10--qvfQ1";
export var gridSpan811 = "Features-module--grid-span-8-11--PBjkB";
export var gridSpan82 = "Features-module--grid-span-8-2--D0abA";
export var gridSpan83 = "Features-module--grid-span-8-3--WA5BM";
export var gridSpan84 = "Features-module--grid-span-8-4--0V-To";
export var gridSpan85 = "Features-module--grid-span-8-5--0j-BM";
export var gridSpan86 = "Features-module--grid-span-8-6--v4W92";
export var gridSpan87 = "Features-module--grid-span-8-7--OObiB";
export var gridSpan88 = "Features-module--grid-span-8-8--LHwQV";
export var gridSpan89 = "Features-module--grid-span-8-9--EDH-k";
export var gridSpan910 = "Features-module--grid-span-9-10--2a70v";
export var gridSpan911 = "Features-module--grid-span-9-11--zBuaV";
export var gridSpan92 = "Features-module--grid-span-9-2--Bifsk";
export var gridSpan93 = "Features-module--grid-span-9-3--72tXT";
export var gridSpan94 = "Features-module--grid-span-9-4--iPn3-";
export var gridSpan95 = "Features-module--grid-span-9-5--A3aO+";
export var gridSpan96 = "Features-module--grid-span-9-6--kNh3B";
export var gridSpan97 = "Features-module--grid-span-9-7--qGPGC";
export var gridSpan98 = "Features-module--grid-span-9-8--EWRpq";
export var gridSpan99 = "Features-module--grid-span-9-9--9g0u4";
export var textBreak = "Features-module--text-break--8HG9B";