import React from "react";

import AutomateImg from "assets/media/images/savings/contribution-automate.jpg";
import TerminalImg from "assets/media/images/savings/contribution-terminal.jpg";

import * as styles from "./Contributions.module.scss";

const Contributions = () => {
  return (
    <section className={styles.Contributions}>
      <div className={styles.Contributions_automate}>
        <h3 className={styles.Contributions_automate_title}>
          You have a business to run. Automate your savings
        </h3>
        <img
          src={AutomateImg}
          alt="automate"
          className={styles.Contributions_automate_img}
        />
      </div>

      <div>
        <span className={styles.Contributions_terminal_meta}>
          AVAILABLE SOON
        </span>

        <h3 className={styles.Contributions_terminal_title}>
          Savings contributions as you sell with Brass Terminals
        </h3>

        <div className={styles.Contributions_terminal_details}>
          <div className={styles.Contributions_terminal_details_img}>
            <img src={TerminalImg} alt="terminal" />
          </div>

          <p className={styles.Contributions_terminal_details_text}>
            With Brass Savings, you can connect your sales from your Brass
            Terminals or set up your revenue account to transfer a portion of
            sales into your Savings.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contributions;
