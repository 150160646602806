import React from "react";

import * as styles from "./SavingsType.module.scss";

interface SavingsInfoProps {
  title: string | React.ReactNode;
  desc: string;
  img: string;
  meta: string;
}

const SavingsInfo: React.FC<SavingsInfoProps> = ({
  title,
  desc,
  meta,
  img,
}) => {
  return (
    <div className={styles.SavingsType_info}>
      <span className={styles.SavingsType_info_meta}>{meta}</span>
      <h1 className={styles.SavingsType_info_title}>{title}</h1>

      <div className={styles.SavingsType_info_content}>
        <div className={styles.SavingsType_info_content_img}>
          <img src={img} alt={meta} />
        </div>
        <p className={styles.SavingsType_info_content_desc}>{desc}</p>
      </div>
    </div>
  );
};

export default SavingsInfo;
